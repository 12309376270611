<template>
  <div>
    <VTitle :title="$t('app.learning_scores')" class="overview-title" />

    <VCard>
      <template #content>
        <slot
          name="score"
          :competencyScore="competencyScore"
          :completionScore="completionScore"
        />

        <Chart type="bar" :data="data" :options="options" />
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";
// Constants
import dateFormats from "@/constants/dateFormats";

export default {
  components: {
    VCard,
    VTitle
  },
  props: {
    completions: {
      type: Object,
      default: () => ({})
    },
    competencies: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { formatDate } = useDisplay();

    // Computed
    const labels = computed(() => {
      const progression = props.competencies;

      return Object.keys(progression).map(date => {
        return formatDate({
          date: date,
          format: dateFormats.HUMAN_SHORTENED_DATE_FORMAT
        });
      });
    });
    const competenciesData = computed(() => Object.values(props.competencies));
    const completionsData = computed(() => Object.values(props.completions));
    const competencyScore = computed(() => competenciesData.value[0]);
    const completionScore = computed(() => completionsData.value[0]);
    const dataSets = computed(() => {
      return [
        {
          label: t("app.competency_score_progression"),
          data: competenciesData.value,
          borderWidth: 2,
          borderColor: "#3160D8",
          backgroundColor: "#3160D8",
          pointBorderColor: "transparent",
          barPercentage: 0.5,
          barThickness: 8,
          maxBarThickness: 6.5,
          minBarLength: 2
        },
        {
          label: t("app.completion_score_progression"),
          data: completionsData.value,
          borderWidth: 2,
          borderColor: "#C6D4FD",
          backgroundColor: "#C6D4FD",
          pointBorderColor: "transparent",
          barPercentage: 0.5,
          barThickness: 8,
          maxBarThickness: 6.5,
          minBarLength: 2
        }
      ];
    });
    const data = computed(() => ({
      labels: labels.value,
      datasets: dataSets.value
    }));
    const options = computed(() => {
      return {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: 11,
                fontColor: "#718096"
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "#D8D8D8",
                zeroLineColor: "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }
          ]
        }
      };
    });

    return {
      competencyScore,
      completionScore,
      data,
      options
    };
  }
};
</script>
