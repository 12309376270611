<template>
  <div>
    <VAlert
      v-if="displayedRows.length === 0"
      :text="$t('app.no_approaching_deadline')"
    />

    <div v-else>
      <VCard
        v-for="(item, index) in displayedRows"
        :key="index"
        :class="[
          { 'mt-4': index !== 0 },
          item?.isExpired ? 'expired' : 'expiring'
        ]"
      >
        <template #content>
          <div class="flex justify-between items-center">
            <div class="flex items-center w-1/2">
              <VImage
                :color="item?.displayed_color"
                :name="item.displayed_name"
                class="mr-2"
              />

              <div>
                <div class="font-medium">
                  {{ item.displayed_name }}
                </div>

                <div class="version">
                  {{ $t("app.versions") }} {{ item.displayed_version }}
                </div>
              </div>
            </div>

            <div class="flex items-center w-1/2">
              <div class="w-1/3">
                <VLine>
                  <template #label>
                    <div class="uppercase font-light text-2xs">
                      {{ $t("app.started_on") }}
                    </div>
                  </template>
                </VLine>

                {{ item?.displayed_started_on }}
              </div>

              <div class="w-1/3">
                <VLine>
                  <template #label>
                    <div class="uppercase font-light text-2xs">
                      {{ $t("app.completion_deadline") }}
                    </div>
                  </template>
                </VLine>

                {{ item?.displayed_completion_deadline }}
              </div>

              <div class="w-1/3">
                <VChip
                  :text="$t(`app.${item?.status}`)"
                  :class="getStatusColor(item?.status)"
                />
              </div>
            </div>
          </div>
        </template>
      </VCard>
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";
// Composables
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VAlert from "@/components/VAlert";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";

export default {
  components: {
    VCard,
    VImage,
    VAlert,
    VLine,
    VChip
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    resource: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // Misc
    const moment = inject("moment");

    // Composables
    const { getText } = useTexts();
    const { formatDate } = useDisplay();
    const { getStatusColor } = useColor();

    // Computed
    const displayedRows = computed(() => {
      return props.items.map(item => ({
        ...item,
        displayed_name: getText(item[props.resource]?.texts, "name"),
        displayed_color: item[props.resource]?.colour,
        displayed_version: item[props.resource]?.version,
        displayed_completion_deadline: formatDate({
          date: item?.completion_deadline,
          fallback: "-"
        }),
        displayed_started_on: formatDate({
          date: item?.started_on,
          fallback: "-"
        }),
        isExpired: isExpired(item.completion_deadline)
      }));
    });

    // Methods
    const isExpired = expiredDate => {
      const today = new Date();

      return moment(today).isSameOrAfter(expiredDate);
    };

    return {
      displayedRows,
      // useColor
      getStatusColor
    };
  }
};
</script>

<style lang="scss">
.expired {
  $color: rgb(220, 38, 38);

  border-left: 5px solid $color;
}
.expiring {
  $color: rgb(250, 204, 21);

  border-left: 5px solid $color;
}
</style>
