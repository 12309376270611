<template>
  <div>
    <VTitle
      :title="$t('app.expiring_competencies', 2)"
      class="overview-title"
    />

    <VAlert
      v-if="displayedRows.length === 0"
      :text="$t('app.no_expiring_competencies')"
    />

    <div v-else>
      <VCard
        v-for="(item, index) in displayedRows"
        :key="index"
        :class="{ 'mt-4': index !== 0 }"
      >
        <template #content>
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <VImage
                :color="item?.source?.colour"
                :name="item.displayed_name"
                class="mr-2"
              />

              <div class="font-medium">
                {{ item.displayed_name }}
              </div>
            </div>

            <div>
              <VLine
                :label="$t('app.obtained_on')"
                :value="item?.displayed_obtained_on"
              />

              <VLine
                :label="$t('app.expires_on')"
                :value="item?.displayed_expires_on"
              />
            </div>
          </div>
        </template>
      </VCard>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VAlert from "@/components/VAlert";
import VLine from "@/components/VLine";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCard,
    VImage,
    VAlert,
    VLine,
    VTitle
  },
  props: {
    competencies: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Composables
    const { getText } = useTexts();
    const { formatDate } = useDisplay();

    // Computed
    const displayedRows = computed(() => {
      return props.competencies.map(competency => ({
        ...competency,
        displayed_name: getText(competency?.source?.texts, "name"),
        displayed_obtained_on: formatDate({ date: competency?.obtained_on }),
        displayed_expires_on: formatDate({ date: competency?.expires_on })
      }));
    });

    return {
      displayedRows
    };
  }
};
</script>
