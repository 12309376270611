<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-2">
        <LearningScoreProgression
          :competencies="data?.competency_score_progression"
          :completions="data?.completion_score_progression"
          class="mb-8"
        />

        <div class="grid grid-cols-2 gap-8 mb-8">
          <LearnerStatistics
            :title="$t('app.trainings', 2)"
            :resource="data?.statistics?.registrations"
          />

          <LearnerStatistics
            :title="$t('app.collections', 2)"
            :resource="data?.statistics?.collections"
          />

          <LearnerStatistics
            :title="$t('app.evaluations', 2)"
            :resource="data?.statistics?.evaluations"
          />

          <LearnerStatistics
            :title="$t('app.attestations', 2)"
            :resource="data?.statistics?.attestations"
          />

          <div class="grid grid-cols-2 gap-4">
            <LearnerStatistics
              :title="$t('app.lessons', 2)"
              :resource="data?.statistics?.lessons"
              hide-results
            />

            <LearnerStatistics
              :title="$t('app.competencies', 2)"
              :resource="data?.statistics?.competencies"
              hide-results
            />
          </div>
        </div>

        <div>
          <div class="flex justify-between items-center">
            <VTitle
              :title="$t('app.approaching_deadline')"
              class="overview-title"
            />

            <div
              class="boxed-tabs nav nav-tabs justify-center bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md"
              role="tablist"
            >
              <VTab
                :name="VIEW.TRAININGS"
                :label="$t('app.trainings', 2)"
                class="btn border-0 shadow-none py-1.5 px-10"
                lowercase-label
                :selected-tab="currentTab"
                @click="updateTab"
              />

              <VTab
                :name="VIEW.COLLECTIONS"
                :label="$t('app.collections', 2)"
                lowercase-label
                class="btn border-0 shadow-none py-1.5 px-10 end-tab"
                :selected-tab="currentTab"
                @click="updateTab"
              />
            </div>
          </div>

          <ApproachingDeadline
            v-if="isCurrentTab(VIEW.COLLECTIONS)"
            :items="data?.collections_approaching_deadline"
            resource="collection"
          />

          <ApproachingDeadline
            v-if="isCurrentTab(VIEW.TRAININGS)"
            :items="data?.trainings_approaching_deadline"
            resource="training"
          />
        </div>
      </div>

      <div>
        <ExpiringCompetencies
          :competencies="data?.expiring_competencies"
          class="mb-8"
        />

        <LatestFeedback :feedback="data.latest_feedback" class="mb-8">
          <template #avatar="{ review }">
            <VImage
              :color="review?.feedbackable?.colour"
              :name="getText(review?.feedbackable?.texts, 'name')"
            />
          </template>

          <template #name="{ review }">
            <div class="uppercase font-light text-xs">
              {{ getType(review?.feedbackable_type) }}
            </div>

            {{ getText(review?.feedbackable?.texts, "name") }}
          </template>
        </LatestFeedback>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
import useTabs from "@/composables/useTabs";
// Components
import VTitle from "@/components/VTitle";
import VImage from "@/components/VImage";
import VTab from "@/components/VTab";
import LatestFeedback from "@/components/templates/LatestFeedback";
import ExpiringCompetencies from "./ExpiringCompetencies";
import LearnerStatistics from "./LearnerStatistics";
import LearningScoreProgression from "./LearningScoreProgression";
import ApproachingDeadline from "./ApproachingDeadline";

export default {
  components: {
    ApproachingDeadline,
    LearningScoreProgression,
    VTitle,
    VImage,
    VTab,
    LatestFeedback,
    ExpiringCompetencies,
    LearnerStatistics
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Constants
    const VIEW = {
      TRAININGS: "trainings",
      COLLECTIONS: "collections"
    };

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { currentTab, updateTab, isCurrentTab } = useTabs(VIEW.TRAININGS);

    // Data
    const data = ref({});
    const isLoading = ref(true);

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.learners", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.firstname ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.learners.overview",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    const getType = type => {
      const types = {
        "App\\Models\\Evaluation": t("app.evaluations"),
        "App\\Models\\Collection": t("app.collections"),
        "App\\Models\\Lesson": t("app.lessons"),
        "App\\Models\\Training": t("app.trainings")
      };

      return types[type] ?? "";
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      getType,
      data,
      isLoading,
      // useTexts
      getText,
      VIEW,
      // useTabs
      currentTab,
      updateTab,
      isCurrentTab
    };
  }
};
</script>
