<template>
  <div>
    <VTitle :title="title" class="overview-title" />

    <VCard>
      <template #content>
        <div :class="{ 'grid grid-cols-2 gap-4': !hideResults }">
          <VStatistics
            :title="$t(`app.statuses`, 2)"
            chart-type="pie"
            :statistics="resource?.statuses"
          />

          <VStatistics
            v-if="!hideResults"
            :title="$t(`app.results`, 2)"
            chart-type="donut"
            :statistics="resource?.results"
          />
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
// Components
import VTitle from "@/components/VTitle";
import VCard from "@/components/VCard";
import VStatistics from "@/components/VStatistics";

export default {
  components: {
    VStatistics,
    VTitle,
    VCard
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    resource: {
      type: Object,
      required: true
    },
    hideResults: {
      type: Boolean,
      default: false
    }
  }
};
</script>
